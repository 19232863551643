
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from "@core/services/AsyncStorage";
import { Location } from "@core/data/location";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';
import { LocalHistoryService } from '@core/services/local-history/local-history.service';
import { environment } from '../../environments/environment';

export { AsyncStorage } from "@core/services/AsyncStorage";
export { Location, Hours, Coords } from "@core/data/location";

@Injectable()
export class LocationsService {

  private _locations: AsyncStorage<Location>;
  private _targetClientId: string;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _localHistory: LocalHistoryService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare location storage
    this._locations = new AsyncStorage(this._http);
    this._locations._setAddFunction(this._addLocation());
    this._locations._setElementComparisonFunction(this._locationComparison);
    this._locations._setSaveActiveElementFunction(this._saveLocation());
    this._locations._setUpdateFunction(this._updateLocations());

    //Set the default location if we have the history
    let defaultLocationId: string = this._localHistory.history.locationId || "";
    if (defaultLocationId)
      this._locations._setDefaultElement({ locationId: defaultLocationId })

    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._targetClientId = client.clientId;
        this._locations.update({ clientId: client.clientId });
      });

    //Update when the location changes
    this._locations.onActiveElementChange()
      .subscribe((location: Location) => {
        this._localHistory.history = { locationId: location.locationId }
      })

  }

  public get activeLocationsClientId(): string { return this._locations.clientId };
  public get locations() { return this._locations };


  private _addLocation(): (location: Location) => Observable<any> {
    let self = this;

    return (location: Location) => {
      location.clientId = self._targetClientId || self._auth.activeClient?.clientId

      return new Observable((observer) => {
        self.getAddressCoordinates(location["data"])
          .then(coordinates => {
            location["lat"] = coordinates != null ? coordinates["lat"] : 0;
            location["lng"] = coordinates != null ? coordinates["lng"] : 0;

            location["data"]["coords"]["proxLat"] = location["data"]["coords"]["lat"] = location["lat"].toString();
            location["data"]["coords"]["proxLng"] = location["data"]["coords"]["lng"] = location["lng"].toString();
            location["data"]["coords"]["radius"] = "10";

            debug.log("location details", location);

            self._addTimezone(location)
              .then(newLocation => {
                this._http.post("location/add", newLocation).subscribe(data => {
                  observer.next(data);
                });
              })
              .catch(err => {
                this._http.post("location/add", location).subscribe(data => {
                  observer.next(data);
                });
              })
          });
      });

    }
  }

  public getAddressCoordinates(rawAddress) {
    let address = [];

    if (rawAddress.address != null && rawAddress.address.trim().length > 0)
      address.push(rawAddress.address);
    if (rawAddress.city != null && rawAddress.city.trim().length > 0)
      address.push(rawAddress.city);
    if (rawAddress.state != null && rawAddress.state.trim().length > 0)
      address.push(rawAddress.state);
    if (rawAddress.prov != null && rawAddress.prov.trim().length > 0)
      address.push(rawAddress.prov);
    if (rawAddress.code != null && rawAddress.code.trim().length > 0)
      address.push(rawAddress.code);
    if (rawAddress.country != null && rawAddress.country.trim().length > 0)
      address.push(rawAddress.country);

    if (address.length > 0) {
      return new Promise((resolve, reject) => {
        this._http.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${environment.GOOGLE_MAPS_API_KEY}&address=${address.join(",")}`).subscribe(
          (data) => {
            var coordinates = null;

            debug.log("from google", data);
            if (data.hasOwnProperty("results") && data["results"] != null && data["results"].length > 0) {
              coordinates = {};
              coordinates.lat = data["results"][0].geometry.location.lat;
              coordinates.lng = data["results"][0].geometry.location.lng;
            }

            resolve(coordinates);
          }
        )
      });
    }
  }

  private _locationComparison(a: Location, b: Location): boolean {

    //Compare the two
    return a && b ? a.locationId === b.locationId : false;

  }

  private _saveLocation(): (location: Location) => Observable<any> {

    let self = this;

    return (location: Location) => {
      debug.log("Adding location:", location);
      location.clientId = self._targetClientId || self._auth.activeClient?.clientId

      return new Observable((observer) => {
        self.getAddressCoordinates(location["data"])
          .then(coordinates => {
            //location["lat"] = coordinates["lat"];
            //location["lng"] = coordinates["lng"];

            self._addTimezone(location)
              .then(newLocation => {
                this._http.post("location/edit", newLocation).subscribe(data => {
                  observer.next(data);
                });
              })
              .catch(err => {
                this._http.post("location/edit", location).subscribe(data => {
                  observer.next(data);
                });
              })
          });
      })

    }

  }

  private _updateLocations(): (client?: Client) => Observable<Location[]> {

    let self = this;
    return () => {

      debug.log("Updating locations");
      let requestData = {
        clientId: self._targetClientId || self._auth.activeClient?.clientId
      }

      return this._http.post("location/allLocations", requestData).pipe(
        map((data: APIResponse) => {

          //Grab the data
          let locations = data.data;

          // sort by timestamp
          locations.sort((leftSide, rightSide): number => {
            if (leftSide.timeStamp < rightSide.timeStamp) return 1
            if (leftSide.timeStamp > rightSide.timeStamp) return -1
            return 0;
          });

          // return all locations
          return locations;

        }));
    }

  }


  private _addTimezone(location: Location): Promise<Location> {

    return new Promise((resolve, reject) => {


      if ("undefined" == typeof location.lat || "undefined" == typeof location.lng) return reject(location);

      debug.log("Polling timezone api...");
      this._http.get("https://api.timezonedb.com/v2.1/get-time-zone?key=EE3IZ0TQLNVG&format=json&by=position&lat=" + location.lat + "&lng=" + location.lng)
        .subscribe((data: TimezoneResponse) => {
          debug.log("Response from timezone api");

          //Set the timezone data
          location.data.timezone = {
            abbreviation: data.abbreviation,
            countryCode: data.countryCode,
            countryName: data.countryName,
            daylightSavingTime: "0" == data.dst ? false : true,
            gmtOffset: data.gmtOffset,
            zoneEnd: data.zoneEnd || 0,
            zoneName: data.zoneName,
            zoneStart: data.zoneStart || 0
          }

          return resolve(location)
        }, () => { reject(location) });

      // if (location.data.timezone) return resolve()
      // location.data.timezone = {};
      // resolve(location);
    });

  }


}


interface TimezoneResponse {
  abbreviation: string;
  countryCode: string;
  countryName: string;
  dst: string;
  formatted: string;
  gmtOffset: number;
  message: string;
  nextAbbreviation: string;
  status: "OK" | "FAILED";
  timestamp: number;
  zoneEnd: number;
  zoneName: string;
  zoneStart: number;
}
